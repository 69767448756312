<template>
  <div class="home">
    <nav class="nav">
      <div class="logo">
        <img src="../assets/img/logo1.png" />
      </div>
      <div class="link">
        <div>
          <router-link to="/">网站首页</router-link>
        </div>
        <div>
          <router-link to="/profile">公司简介</router-link>
        </div>
        <div>
          <router-link to="/product">产品中心</router-link>
        </div>
        <div>
          <router-link to="/technical">技术服务</router-link>
        </div>
        <div>
          <router-link to="/problem">常见问题</router-link>
        </div>
        <div>
          <router-link to="/message">在线留言</router-link>
        </div>
        <div>
          <router-link to="/news">新闻中心</router-link>
        </div>
      </div>
    </nav>
    <div class="body">
      <div class="pic">
        <el-carousel height="150px">
          <el-carousel-item v-for="item in 1" :key="item">
            <img width="100%" height="100%" src="../assets/img/pic.jpg" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <router-view />
    </div>
    <div class="footer">
      <div>2022绵阳今为材料科技有限公司 版权所有 粤ICP备2022043249号-2</div>
      <div @click="tobackground()" class="background">后台管理</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pic: []
    };
  },
  methods:{
    tobackground(){
      window.location.href = "http://admin.mykingv.com/"
    }
  }
};
</script>

<style lang="less" scoped>
.home {
    // margin-top: 60px;
  .nav {
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 100;
    display: flex;
    align-items: center;
    background-color: #51ace0;
    .logo {
      margin-left: 10px;
      margin-right: 15%;
      img {
        width: 350px;
        height: 50px;
      }
    }
    .link {
      width: 600px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      div{
        width: 100%;
        height: 100%;
        line-height: 60px;
        text-align: center;
      }
      div:hover {
        background-color: #1f343e;
      }
    }
    a {
      font-weight: bold;
      color: #fff;
      &.router-link-exact-active {
        color: #cccccc;
      }
    }
  }
  .body {
    padding: 60px 10%;
    .pic {
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      .el-carousel {
        width: 100%;
        /deep/ .el-carousel__container {
          height: 600px !important;
        }
        .el-carousel__item h3 {
          color: #475669;
          font-size: 14px;
          opacity: 0.75;
          line-height: 150px;
          margin: 0;
        }
        .el-carousel__item:nth-child(2n) {
          background-color: #99a9bf;
        }
        .el-carousel__item:nth-child(2n + 1) {
          background-color: #d3dce6;
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    background-color: #51ace0;
    color: #fff;
    .background:hover{
      cursor: pointer;
    background-color: #99a9bf;
    }
  }
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "",
        name: "homeWeb",
        component: () => import("../views/home.vue"),
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("../views/profile.vue"),
      },
      {
        path: "product",
        name: "product",
        component: () => import("../views/product.vue"),
      },
      {
        path: "technical",
        name: "technical",
        component: () => import("../views/technical.vue"),
      },
      {
        path: "problem",
        name: "problem",
        component: () => import("../views/problem.vue"),
      },
      {
        path: "message",
        name: "message",
        component: () => import("../views/message.vue"),
      },
      {
        path: "news",
        name: "news",
        component: () => import("../views/news.vue"),
      },
      {
        path: "productDetail",
        name: "productDetail",
        component: () => import("../views/productDetail.vue"),
      },
      {
        path: "newsDetail",
        name: "newsDetail",
        component: () => import("../views/newsDetail.vue"),
      },
      {
        path:"technicalDetail",
        name:"technicalDetail",
        component: () => import("../views/technicalDetail.vue"),
      }
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;
